import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import ProductsPage from "pages/products"

export const Head = () => (
  <SEO
    title="商品總覽"
    description="國際知名成衣品牌，百萬件現貨，上萬種品項，累計銷售超過3000萬件，深受客戶好評。"
    pathname="/products"
  />
)

export default () => (
  <Layout>
    <ProductsPage />
  </Layout>
)
