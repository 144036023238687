import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { StaticQuery, graphql } from "gatsby"
import Banner from "./banner"
import CategoryList from "./category/choosableList"
import ProductList from "./productList"
import { scrollPageTo } from 'utils'
import "./styles.scss"

const getFromSearch = data => {
  let search = queryString.parse(window.location.search, { arrayFormat: 'comma' })

  if (!Array.isArray(search.tags)) search = { ...search, tags: [search.tags] }

  return {
    keyword: search.keyword,
    tags: data.allContentfulProductTag.edges.reduce((tags, cur) => {
        if (search.tags.find(nameOfTag => nameOfTag === cur.node.name)) return tags.concat(cur)
        return tags
      }, [])
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulProductTag(sort: { fields: [group_number, order] order: [ASC, DESC] }) {
          edges {
            node {
              id
              name
              group_number
            }
          }
        }
        allContentfulProduct(sort: { fields: [order, createdAt] order: [DESC, DESC] }) {
          edges {
            node {
              id
              slug
              name
              fit_men
              fit_women
              fit_kids
              product_colors {
                title
                gatsbyImageData(layout: CONSTRAINED, width: 500)
              }
              product_tags {
                id
                name
                group_number
              }
            }
          }
        }
      }
    `}
    render={data => {
      const [selectedTags, setSelectedTags] = useState([])
      const [keyword, setKeyword] = useState(null)
      const [didMount, setDidMount] = useState(false)

      useEffect(() => {
        const { tags } = getFromSearch(data)

        if (tags?.length > 0) scrollPageTo('.product-list-page-container', 500, -30)
      }, [])

      useEffect(() => {
        const { tags: initTags, keyword: initKeyword } = getFromSearch(data)
        setSelectedTags(initTags)
        setKeyword(initKeyword)

        window.onpopstate = function(event) {
          const { tags: newTags, keyword: newKeyword } = getFromSearch(data)
          setSelectedTags(newTags)
          setKeyword(newKeyword)
        }

        setDidMount(true)

        return () => {
          setSelectedTags(false)
          setKeyword(false)
          window.onpopstate = null
        }
      }, [(typeof window === 'undefined') ? null : window.location.href])

      const handleTagChange = (targetTag, newIsSelected, isInit) => {
        let newTags, params

        if (isInit || selectedTags.find(tag => tag.node.group_number === 1)) {
          newTags = [targetTag]
          params = { tags: [targetTag.node.name] }
        } else {
          newTags = data.allContentfulProductTag.edges.reduce((tags, cur) => {
            let selected = false
            if (selectedTags.find(tag => tag.node.id === cur.node.id)) selected = true
          
            if (cur.node.id === targetTag.node.id) selected = newIsSelected
            if (selected) return tags.concat(cur)
            return tags
          }, [])

          const search = queryString.parse(window.location.search, { arrayFormat: 'comma' })
          params = { ...search, tags: newTags.map(tag => tag.node.name) }
        }

        const qs = queryString.stringify(params, { arrayFormat: 'comma' })

        if (window.history.pushState) {
          const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${qs}`
          window.history.pushState({ path: url }, '', url)
        }

        setSelectedTags(newTags)
      }

      const clearSearch = () => {
        if (window.history.pushState) {
          const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
          window.history.pushState({ path: url }, '', url)
        }

        setSelectedTags([])
        setKeyword(null)
      }

      const productsData = data.allContentfulProduct.edges.filter(product => {
        const canIgnoreTags = selectedTags.length === 0
        const canIgnoreKeyword = !keyword || keyword.length === 0

        return (
          product.node.product_colors.length > 0 &&
          (canIgnoreTags || selectedTags.find(tag => product.node.product_tags.find(productTags => productTags.id === tag.node.id))) &&
          (canIgnoreKeyword || product.node.product_tags.find(productTags => productTags.name.includes(keyword)))
        )
      })

      return (
        <>
          <Banner />
          <div className="product-list-page-container">
            <CategoryList
              list={data.allContentfulProductTag.edges}
              onClear={clearSearch}
              onTagChange={handleTagChange}
              hasKeyword={keyword && keyword.length > 0}
              selectedTags={selectedTags}
            />
            {
              didMount && <ProductList data={productsData} keyword={keyword} selectedTags={selectedTags} />
            }
          </div>
        </>
      )
    }}
  />
)
