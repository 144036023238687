import { Icon } from "@blueprintjs/core"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { createUltimatePagination } from "react-ultimate-pagination"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { scrollPageTo } from "utils"
import "./styles.scss"

const Page = props => (
  <span
    className={classNames('btn-page-number', props.isActive && 'actived')}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.value}
  </span>
)

const FirstPageLink = ({ isActive, onClick }) => (
  <span className="icon-btn" onClick={onClick}>
    <Icon
      icon="step-backward"
      iconSize={19}
      style={{ color: 'rgb(110, 110, 110)' }}
    />
  </span>
)

const LastPageLink = ({ isActive, onClick }) => (
  <span className="icon-btn" onClick={onClick}>
    <Icon
      icon="step-forward"
      iconSize={19}
      style={{ color: '#343434' }}
    />
  </span>
)

const Ellipsis = props => <span className="btn-ellipsis" onClick={props.onClick} disabled={props.disabled}>...</span>
const PreviousPageLink = (props) => <button onClick={props.onClick} disabled={props.disabled}>Previous</button>
const NextPageLink = props => <button onClick={props.onClick} disabled={props.disabled}>Next</button>
const Wrapper = props => <div className="pagination">{props.children}</div>

const itemTypeToComponent = {
  'PAGE': Page,
  'ELLIPSIS': Ellipsis,
  'FIRST_PAGE_LINK': FirstPageLink,
  'PREVIOUS_PAGE_LINK': PreviousPageLink,
  'NEXT_PAGE_LINK': NextPageLink,
  'LAST_PAGE_LINK': LastPageLink
}

const UltimatePagination = createUltimatePagination({
  itemTypeToComponent: itemTypeToComponent,
  WrapperComponent: Wrapper
})

const ProductItem = ({ product }) => {
  const coverIndex = product.node.product_colors.findIndex(color => color.is_cover)
  const branches = product.node.product_tags.reduce((tags, tag) => tags + (tag.group_number === 4 ? tag.name : ''), '')
  const tags = product.node.product_tags.reduce((tags, tag) => tags + (tag.group_number === 0 ? tag.name : ''), '')

  return (
    <div className="product-item" key={product.node.id}>
      <Link to={`/product/${product.node.slug}`}>
        <GatsbyImage
          image={product.node.product_colors[coverIndex > 0 ? coverIndex : 0].gatsbyImageData}
          alt={product.node.product_colors[coverIndex > 0 ? coverIndex : 0].title}
        />
        {
          branches && <div className="product-item-branch"><span className="tag-label">{branches}</span></div>
        }
        <div className="product-item-name"><span className="tag-label">{tags}</span>{product.node.name}</div>
      </Link>
    </div>
  )
}

export default ({ data, keyword, selectedTags }) => {
  const totalPages = Math.ceil(data.length / 18)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    setPageNumber(1)
  }, [data.length])

  const dom = []
  const offset = (pageNumber - 1) * 18
  let groupTemps = []

  data.slice(offset, offset + 18).forEach((product, index, arr) => {
    groupTemps.push(product)

    if (index > 0 && (index + 1) % 9 === 0) {
      const groupDOM = (
        <div className="grid-item-group" key={groupTemps.reduce((key, product) => key += product.node.id, '')}>
          { groupTemps.slice(0, groupTemps.length - 1).map(product => <ProductItem product={product} key={product.node.id} />) }
        </div>
      )
      const heroDOM = (
        <div className="grid-item-hero" key={groupTemps[groupTemps.length - 1].node.id}>
          <ProductItem product={groupTemps[groupTemps.length - 1]} />
        </div>
      )
      if (Math.floor(index / 9) % 2 === 0) {
        dom.push(groupDOM)
        dom.push(heroDOM)
      } else {
        dom.push(heroDOM)
        dom.push(groupDOM)
      }
      groupTemps = []
    }
  })

  if (groupTemps.length > 0) { // 塞剩下的、或是根本不到九個
    dom.push(
      <div className="grid-item-group no-hero" key="rest-of-grid-item-groups">
        { groupTemps.map(product => <ProductItem product={product} key={product.node.id} />) }
      </div>
    )
  }

  return (
    <div className="product-list-container">
      {
        (!!keyword || selectedTags?.length > 0) && (
          <div className="product-list-search-bar">
            {
              keyword && <span className="search-keyword">搜尋關鍵字【<span className="search-keyword-label">{keyword}</span>】</span>
            }
            {
              selectedTags && <h1>{selectedTags.reduce((arr, tagObj) => arr.concat(tagObj.node.name), []).join(', ')}</h1>
            }
            符合條件商品共
            <span className="search-result-count">{data.length}</span>件
          </div>
        )
      }
      <div className="product-list">{dom}</div>
      {
        totalPages > 1 && (
          <div className="pagination-container">
            <UltimatePagination
              currentPage={pageNumber}
              totalPages={totalPages}
              hidePreviousAndNextPageLinks
              onChange={page => {
                scrollPageTo('.product-list-search-bar')
                setPageNumber(page)
              }}
            />
          </div>
        )
      }
    </div>
  )
}
