import { Checkbox } from "@blueprintjs/core"
import classNames from "classnames"
import React from "react"
import "./styles.scss"

export default ({ list = [], onClear, onTagChange, hasKeyword, selectedTags }) => {
  let currentGroupNumber

  return (
    <div className="cate-container">
      <div className="fixed-cates">
        <div
          className={classNames('cate-item', !hasKeyword && selectedTags.length === 0 && 'selected')}
          onClick={onClear}
        >
          全部商品
        </div>
        {
          list.reduce((components, tag) => {
            if (tag.node.group_number !== 1) return components
            const isSelected = !!selectedTags.find(selectedTag => selectedTag.node.id === tag.node.id)

            return components.concat(
              <div
                key={`cate-${tag.node.id}`}
                className={classNames('cate-item', isSelected && 'selected')}
                onClick={() => onTagChange(tag, true, true)}
              >
                {tag.node.name}
              </div>
            )
          }, [])
        }
      </div>
      {
        list.reduce((components, tag) => {
          if (tag.node.group_number < 2) return components

          let showDivider = false
          // TODO: move filter logic to parent
          const isSelected = !!selectedTags.find(selectedTag => selectedTag.node.id === tag.node.id)

          if (tag.node.group_number !== currentGroupNumber) {
            showDivider = true
            currentGroupNumber = tag.node.group_number
          }

          return components.concat(
            <Checkbox
              key={`cate-${tag.node.id}`}
              className={classNames('cate-item', showDivider && `with-top-border-${currentGroupNumber % 3 + 1}`)}
              checked={isSelected}
              label={tag.node.name}
              onChange={() => onTagChange(tag, !isSelected)}
            />
          )
        }, [])
      }
    </div>
  )
}
